import { useEffect, useLayoutEffect, useState } from "react";
import './Menu.css'
import gsap from "gsap";
import homeIcon from './Icons/home.svg'
import compassIcon from './Icons/help.svg'
import searchIcon from './Icons/search.svg'
import businessIcon from './Icons/business.svg'
import { Parallax } from "../Parallax";

export function Menu() {
    const menuItems = [
        { id: 'home', title: 'Home', icon: homeIcon},
        { id: 'description', title: '¿Qué es?', icon: compassIcon},
        { id: 'que_ofrece', title: '¿Qué ofrece?', icon: searchIcon},
        { id: 'section4', title: 'Soy negocio', icon: businessIcon},
    
      ];
    const [activeSection, setActiveSection] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const t1 = gsap.timeline();
            t1.from("#menu_logo", {
                opacity: 0,
                duration: 0.5,
                delay: 0.2,
            }).from("#menu_item", {
                opacity: 0,
                yPercent: "-100",
                stagger: 0.2,
            }).to("#menu_item", {
                opacity: 1,
                yPercent: '0',
            }).to("#menu_logo", {
                opacity: 1,
            })
        })
        return () => ctx.revert()
    }, []);

    return (
            isMobile ? 
            <div id="navbar_mobile" className={`p-2 w-100 d-flex flex-column position-fixed bottom-0`}>
                <div className={`d-flex justify-content-between bg-dark px-4 py-2`} style={{borderRadius: 60}}>
                    {menuItems.map((item, id) => (
                        <div key={id}>
                            <a 
                                className={`text-light text-decoration-none d-flex align-items-center flex-column ${activeSection === item.title ? 'text-decoration-underline' : ''}`} 
                                href={`#${item.id}`}
                                onClick={() => setActiveSection(item.title)}

                                >
                                <img className="mb-1" style={{width:'1.6rem'}} src={item.icon} alt="" />
                                <p className="m-0 p-0" style={{fontSize: '.8rem'}}>{item.title}</p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
            :
            <div style={{
                background: 'linear-gradient(#000, transparent)',
                zIndex: 3
            }} className={`p-4 w-100 d-flex justify-content-between position-fixed align-items-center`}>
                <div id="menu_logo" className="d-flex">
                    <h1 className="text-light"><b>Near</b><b style={{color: "#1FB0A7"}}>U</b></h1>
                </div>
                <div className={`d-flex`}>
                    {menuItems.map((item, id) => (
                        <div id="menu_item" className="mx-1" key={id}>
                            <b>
                                <a 
                                    className={`text-decoration-none ${activeSection === item.title ? 'selected' : 'menu-item'}`} 
                                    href={`#${item.id}`}
                                    onClick={() => setActiveSection(item.title)}
                                    >
                                    {item.title}
                                </a>
                            </b>
                        </div>
                    ))}
                </div>
            </div>
    );
}
