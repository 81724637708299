import React from 'react';
import './PlaceCard.css';
import _image from './images/image3.png';

function EventCard({ name, distance, image, type, musicGenres }) {
    let eventImage;
    try {
        eventImage = require(`${image}`);
    } catch (err) {
        eventImage = require('./images/image1.png');
    }

    return (
        <div id='event' className="card">

            <img src={eventImage} alt={name} className="image" />
            <div className="info">
                <div className='name-container'>
                    <p className='name'>{name}</p>
                </div>
                <p className='type'>{type}</p>
                <p className='distance'>{distance}</p>
            </div>
        </div>
    );
}

export default EventCard;

