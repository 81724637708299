import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "@studio-freight/hamo";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function Parallax({ className, children, axis = 'y', speed = 1, id = "parallax" }) {
  const trigger = useRef();
  const target = useRef();
  const timeline = useRef();
  const { width: windowWidth } = useWindowSize();

  
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const distance = windowWidth * speed * 0.1;

    // Inicializar la posición del target a 0
    const setPosition = gsap.quickSetter(target.current, axis, "px");

    timeline.current = gsap.timeline({
      scrollTrigger: {
        id: id,
        trigger: trigger.current,
        scrub: true,
        start: "top bottom",
        end: "bottom top",
        onUpdate: (self) => {
          setPosition(self.progress * distance);
        },
      },
    });

    return () => {
      timeline.current?.kill();
    };
  }, [id, speed, windowWidth, axis]);

  return (
    <div id={id} ref={trigger} className={className}>
      <div ref={target}>{children}</div>
    </div>
  );
}
