import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import mockup from './img2.png';
import bg from './bg.jpg';
import { Parallax } from '../Parallax';
import WordCarousel from '../Banner/WordCarousel';
import MouseMoveEffect from '../MouseMoveEffect';

gsap.registerPlugin(ScrollTrigger);

export function Description({ speed, isMobile }) {
    const imgRef = useRef(null);
    const titleRef = useRef(null);
    const textRef = useRef(null);
    const buttonRef = useRef(null);

    useLayoutEffect(() => {
        const imgElement = imgRef.current;
        const titleElement = titleRef.current;
        const textElement = textRef.current;
        const buttonElement = buttonRef.current;

        // Animación para la imagen
        gsap.fromTo(imgElement, 
            { opacity: 0, yPercent: 30 }, 
            { opacity: 1, yPercent: 0, duration: 1, scrollTrigger: { trigger: imgElement } }
        );

        // Animación para el título
        gsap.fromTo(titleElement, 
            { opacity: 0, y: 50 }, 
            { opacity: 1, y: 0, duration: 1, scrollTrigger: { trigger: titleElement, start: "top bottom", end: "top top" } }
        );

        // Animación para el texto
        gsap.fromTo(textElement, 
            { opacity: 0, y: 50 }, 
            { opacity: 1, y: 0, duration: 1, scrollTrigger: { trigger: textElement, start: "top bottom", end: "top top" } }
        );

        // Animación para el botón
        gsap.fromTo(buttonElement, 
            { opacity: 0, y: 50 }, 
            { opacity: 1, y: 0, duration: 1, delay: 0.5, scrollTrigger: { trigger: buttonElement, start: "top bottom", end: "top top" } }
        );

    }, []);

    return (
        <div 
            id='description' 
            className="p-3 p-md-5" 
            style={{
                height: '100vh', 
                background: `url(${bg})`,
                width: "100%",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
        >
            <div id='animated_img' className='row h-100'>
                <div className='col-12 col-md-6 d-md-block d-none' ref={imgRef}>
                    <Parallax speed={-3} className={'self-start'}>
                        <MouseMoveEffect sensitivity={-10}>
                            <img className='w-100 px-5 mt-5' src={mockup} alt="Mockup" />
                        </MouseMoveEffect>
                    </Parallax>
                </div>
                <div className='col-12 col-md-6 h-100 d-flex flex-column align-items-start justify-content-center'>
                    <div className='pb-2 d-flex align-items-center justify-content-center'>
                        <h1 className='m-0 title text-start' ref={titleRef}>
                            <b>¿Qué es Near</b><b style={{ color: "#1FB0A7" }}>U</b><b>?</b>
                        </h1>
                    </div>
                    <WordCarousel />
                    <h6 className="text-start pt-3" style={{ fontWeight: "500", color: '#495057', fontSize: "1.35rem" }} ref={textRef}>
                        <b id="line" style={{ fontWeight: "700" }}>Near</b><b style={{ color: "#1FB0A7" }}>U</b> es una nueva <b>red social</b> para descubrir y compartir
                        los mejores planes de ocio cerca de ti.📍 
                        <br /> 
                        <br /> 
                        <p id="line">Recibe <b>recomendaciones personalizadas</b> para ti
                        y descubre los lugares donde salen tus amigos.</p>
                        <br /> 
                        <p  id="line" >¡Encuentra los mejores planes de tu zona con <b style={{ fontWeight: "700" }}>Near</b><b style={{ color: "#1FB0A7" }}>U</b>!</p>
                        <br /> 
                        <br /> 
                        <p id="line" className='mt-2 text-center' style={{fontWeight: 700, fontSize: '0.7rem'}}>Completa esta encuesta y deja tu huella en nuestra app.</p>

                    </h6>
                    {
                        <div ref={buttonRef} className='w-100 d-flex flex-column justify-content-start align-items-center'>
                            <button onClick={() => window.open('https://docs.google.com/forms/d/1F9F1KYb5jHFbMn5qy-Bw4DwD--r52UHSrr4ugvckr2Q', '_blank')} className='btn btn-nearu px-4 py-2' >
                                <p id="line" className='m-0'>Completa la encuesta ahora</p>
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
