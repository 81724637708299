import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';


const WordCarousel = () => {
  const words = ["Fiestas", "Bares", "Discotecas", "Pubs", "Eventos", "Salas", "Conciertos"];
  const article = ["las", "los", "las", "los", "los", "las", "los"];

  const [index, setIndex] = useState(0);
  const wordRef = useRef(null);
  
  const splitText = (text) => {
    return text.split('').map((char, i) => (
      <span key={i} className="letter" id='char'>
        {char}
      </span>
    ));
  };
  
  useEffect(() => {
    const transitionTime = 0.5; // tiempo de transición en segundos
    const visibleTime = 1; // tiempo visible en segundos

    const tl = gsap.timeline({ repeat: -1, repeatDelay: visibleTime });

    tl.to(wordRef.current, { y: -100, opacity: 0, stagger: 0.2, duration: transitionTime, onComplete: () => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
      } })
      .set(wordRef.current, { y: 100 })
      .to(wordRef.current, { y: 0, opacity: 1, duration: transitionTime});

    return () => {
      tl.kill();
    };
  }, [words.length]);

  return (
    <>
      <div className="d-flex flex-wrap w-100 py-3" >
        <h4 style={{fontWeight: 800}} className='m-0'>Descubre {article[index]} mejores</h4>
        <div className='px-2' style={{overflow: 'hidden'}}>
          <div
            ref={wordRef}
            style={{
              position: 'relative',
            }}
          >
            <h4 className='m-0 split' style={{fontWeight: 800, color: "#1FB0A7"}}>
              {splitText(words[index])}
            </h4>
          </div>
        </div>
        <h4 className='m-0'style={{fontWeight: 800}}>cerca de ti</h4>
      </div>
    </>
  );
};

export default WordCarousel;
