import React, { useEffect, useState } from "react";
import './NoteSection.css'; // Importa los estilos CSS para la sección
import bg from './bg2.jpg';
import chat from './img/chat.png';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import img1 from './img/img1.png';
import img2 from './img/img2.png';
import img3 from './img/img3.png';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

// Componente Note para representar cada apunte individual
function Note({ id, content, img, isMobile }) {
  return (
    isMobile ?
      <div className="note-card d-flex align-items-center shadow-sm my-2">
        <div className={`col-7 ${id % 2 === 0 ? 'd-none' : ''}`}>
          <img className="note-img" style={{ maxHeight: '14rem', height: 'auto', maxWidth: '100%' }} src={img} alt="" />
        </div>
        <div className="d-flex flex-column col-5">
          <h2 className="note-title">{id + '.'}</h2>
          <h4 className="note-content">{content}</h4>
        </div>
        <div className={`col-7 ${id % 2 !== 0 ? 'd-none' : ''}`}>
          <img className="note-img" style={{ maxHeight: '14rem', height: 'auto', maxWidth: '100%' }} src={img} alt="" />
        </div>
      </div>
      :
      <div className="note-card note-card-pc rounded shadow-sm">
        <img className="note-img" style={{ maxHeight: '20rem', margin: '1rem'}} src={img} alt="" />
        <h4 className="note-content">{content}</h4>
      </div>
  );
}

// Componente NoteSection que contiene la lista de apuntes
export function NoteSection() {
  const [showUsers, setShowUsers] = useState(true); // Estado para alternar entre usuarios y negocios
  
  const notes = [
    { id: 1, content: "Estarás al día de todas las fiestas y eventos de tu zona", img: img1 },
    { id: 2, content: "Encontrarás fácilmente lugares para salir en ciudades que no conoces", img: img2 },
    { id: 3, content: "Conecta con gente que se encuentra en tu mismo evento", img: img3 },
  ];
  const business = [
    { id: 1, content: "Llega a usuarios de la zona que buscan un lugar para salir", img: img1 },
    { id: 2, content: "Publica tus eventos para que lo vean las personas en tu ciudad y alrededores", img: img2 },
    { id: 3, content: "Recibe feedback directo de los usuarios y adapta tus ofertas a las preferencias locales", img: img3 },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Ajusta el tamaño según tus necesidades

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleView = () => {
    setShowUsers(prevShowUsers => !prevShowUsers);
  };

  useEffect(() => {
    gsap.fromTo('.section-title', 
      { opacity: 0, y: 50 }, 
      { opacity: 1, y: 0, duration: 1, scrollTrigger: { trigger: '.section-title' } }
    );

    gsap.fromTo('.toggle-switch', 
      { opacity: 0, x: -50 }, 
      { opacity: 1, x: 0, duration: 1, scrollTrigger: { trigger: '.toggle-switch' } }
    );

  }, []);

  useEffect(() => {
    gsap.fromTo('.note-card', 
      { opacity: 0, y: 50 }, 
      { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
    );
  }, [showUsers]);

  return (
    <div 
      id="que_ofrece" 
      style={{
        height: isMobile ? 'auto' : '100vh', 
        background: `url(${bg})`,
        width: "100%",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className="note-section py-4"
    >
      <h1 className="section-title text-light pt-5">
        ¿Qué ofrece <b>Near</b><b style={{ color: "#1FB0A7" }}>U</b>?
      </h1>
      
      <div>
        <div className="w-100 py-3 d-flex justify-content-center">
          <div onClick={toggleView} className="toggle-switch text-light p-1">
            <div className={`d-flex item justify-content-center ${showUsers ? 'active' : ''}`}>
              <p className="m-0">Usuarios</p>
            </div>
            <div className={`d-flex item justify-content-center ${showUsers ? '' : 'active'}`}>
              <p className="m-0">Negocios</p>
            </div>
            <label className="toggle-switch-label" htmlFor="toggleSwitch">
              <span className="toggle-switch-inner" />
              <span className="toggle-switch-switch" />
            </label>
          </div>
        </div>
        <TransitionGroup>
          <CSSTransition
            key={showUsers ? 'users_option' : 'business_option'}
            timeout={500}
            classNames="fade"
          >
            <div className="row m-0">
              {showUsers ? (
                <div className="users_option row m-0">
                  {notes.map((note, index) => (
                    <div key={index} className="col-md-4">
                      <Note id={note.id} content={note.content} img={note.img} isMobile={isMobile}/>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="business_option row m-0">
                  {business.map((note, index) => (
                    <div key={index} className="col-md-4">
                      <Note id={note.id} content={note.content} img={note.img} isMobile={isMobile}/>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}
