import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const MouseMoveEffect = ({sensitivity = 10, children}) => {
  const ref = useRef(null);

  useEffect(() => {
    const update = ({ x, y }) => {
      const el = ref.current;
      if (el) {
        gsap.to(el, {
          x: gsap.utils.mapRange(0, window.innerWidth, -sensitivity, sensitivity,  x),
          y: gsap.utils.mapRange(0, window.innerHeight, -sensitivity, sensitivity, y),
          duration: 0.2,
          overwrite: 'auto'
        });
      }
    };

    window.addEventListener('pointermove', update);

    return () => {
      window.removeEventListener('pointermove', update);
    };
  }, []);

  return (
    <div ref={ref}>
        {children}
    </div>
  );
};

export default MouseMoveEffect;
