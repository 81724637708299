import React, { useEffect } from 'react';
import './BusinessIncentive.css'; // Archivo CSS para estilos
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const BusinessIncentive = () => {
  useEffect(() => {
    // Animación de entrada para el título
    gsap.fromTo('.business-incentive-title', 
      { opacity: 0, y: -50 }, 
      { opacity: 1, y: 0, duration: 1, scrollTrigger: { trigger: '.business-incentive-title' } }
    );

    // Animación de entrada para el texto
    gsap.fromTo('.business-incentive-text', 
      { opacity: 0, y: 30 }, 
      { opacity: 1, y: 0, duration: 1, delay: 0.2, scrollTrigger: { trigger: '.business-incentive-text' } }
    );

    // Animación de entrada para la lista de beneficios
    gsap.fromTo('.business-incentive-benefits li', 
      { opacity: 0, y: 20 }, 
      { opacity: 1, y: 0, duration: 0.8, stagger: 0.1, delay: 0.4, scrollTrigger: { trigger: '.business-incentive-benefits' } }
    );

    // Animación de entrada para el botón
    gsap.fromTo('.business-incentive-button', 
      { opacity: 0, y: 50 }, 
      { opacity: 1, y: 0, duration: 1, delay: 0.6, scrollTrigger: { trigger: '.business-incentive-button' } }
    );
  }, []);

  return (
    <div id='section4' className="business-incentive-container">
      <div className="business-incentive-content">
        <h1 className="business-incentive-title">¡Obtén 3 meses gratis del plan premium!</h1>
        <p className="business-incentive-text">
          Completa nuestro breve formulario y disfruta de todas las ventajas exclusivas de nuestro <b>plan premium</b> en el momento del lanzamiento.
          Aprovecha esta oportunidad para potenciar tu negocio por tan solo <b>2 minutos</b> de tu tiempo.
        </p>
        <ul className="business-incentive-benefits">
          <li>Acceso a funcionalidades premium</li>
          <li>Soporte prioritario</li>
          <li>Visibilidad destacada en la app</li>
          <li>Y mucho más...</li>
        </ul>
        <button className="business-incentive-button" onClick={() => window.open('https://docs.google.com/forms/d/1vLv_nzXj6v0HE0WUqJmoO_bywepcT9ztpm2BB_PJYPw/edit', '_blank')}>
          Completa el formulario ahora
        </button>
      </div>
    </div>
  );
};

export default BusinessIncentive;
