import './App.css';
import { Menu } from './Comps/Menu/Menu';
import { Description } from './Comps/Description/Description';

import { NoteSection } from './Comps/NoteSection/NoteSection';
import BusinessIncentive from './Comps/BusinessIncentive/BusinessIncentive';
import { useEffect, useState } from 'react';
import { Home } from './Comps/Home/Home';
import { Analytics } from "@vercel/analytics/react"
import PlaceList from './Comps/PlaceList/PlaceList';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}, []);

  return (
    <div className="App">
      <Analytics />
      <Menu></Menu>
      <div data-bs-spy="scroll" data-bs-target="#navbar_mobile" data-bs-smooth-scroll="true" class="scrollspy-example-2" tabindex="0">
        <Home isMobile={isMobile}></Home>
        <Description speed={2} isMobile={isMobile}></Description>
        <NoteSection></NoteSection>
        <BusinessIncentive></BusinessIncentive>
      </div>
    </div>
  );
}

export default App;
