import React from 'react';
import './ProfileCard.css';
import _image from './images/image3.png';

function ProfileCard({ name, distance, image, type }) {
    let eventImage;
    try {
        eventImage = require(`${image}`);
    } catch (err) {
        eventImage = require('./images/profiles/pacha.png');
    }

    return (
        <div id='profile' className="card">
            <img src={eventImage} alt={name} className="image" />
                <div className='name-container py-2'>
                    <p className='name'>{name}</p>
                </div>
        </div>
    );
}

export default ProfileCard;

