import React from 'react';
import PlaceCard from './PlaceCard';
import EventCard from './EventCard';
import './PlaceCard.css';
import ProfileCard from './ProfileCard';

export function PlaceList() {
    const places = [
        { name: 'Terraza Círculo de Bellas Artes', distance: '150 m', image: 'circulo_bellas_artes.jpg', type: 'Terrace' },
        { name: 'Museo del Prado', distance: '1,2 km', image: 'ipu7chnajqua5bnm8swl.webp', type: 'Museum' },
        { name: 'Discoteca Pacha Ibiza', distance: '3 km', image: 'pacha-ibiza.webp', type: 'Nightclub' },
        { name: 'Mercado de San Miguel', distance: '500 m', image: 'Mercado-San-Miguel_2352374850_22284906_1300x731.jpg', type: 'Market' },
        { name: 'Alhambra de Granada', distance: '4 km', image: 'que-es-la-alhambra-de-granada.jpg', type: 'Landmark' },
        { name: 'Parque Güell', distance: '2,5 km', image: 'bb.jpg', type: 'Park' },
        { name: 'Terraza Azotea del Círculo', distance: '300 m', image: 'azotea-circulo.jpg', type: 'Terrace' },
        { name: 'Discoteca Kapital', distance: '1,8 km', image: '1568190236_kapital-2-3.jpg', type: 'Nightclub' },
        { name: 'Terraza 360º', distance: '750 m', image: 'terraza.jpg', type: 'Terrace' },
        { name: 'Sala Apolo', distance: '2 km', image: 'thumb_7155_default_card.jpeg', type: 'Nightclub' },
        { name: 'El Jardín Secreto', distance: '400 m', image: 'el_jardin_secreto.jpg', type: 'Bar' },
        { name: 'Discoteca Opium Madrid', distance: '3,5 km', image: '1568138155_opium.jpg', type: 'Nightclub' },
        { name: 'Terraza del Hotel Santo Domingo', distance: '1,3 km', image: 'SUNSET-LOOKERS-Atardeceres-de-primera-DESTACADA-770x466.jpg', type: 'Terrace' },
        { name: 'La Venencia', distance: '700 m', image: '09.06.09.La-Venencia-1.018-1-2-500x300.jpg', type: 'Bar' },
        { name: 'Sala Razzmatazz', distance: '2,7 km', image: 'sala-razzmatazz.jpg', type: 'Nightclub' },
        { name: 'Terraza Círculo de Bellas Artes', distance: '150 m', image: 'circulo_bellas_artes.jpg', type: 'Terrace' },
        { name: 'Museo del Prado', distance: '1,2 km', image: 'ipu7chnajqua5bnm8swl.webp', type: 'Museum' },
        { name: 'Discoteca Pacha Ibiza', distance: '3 km', image: 'pacha-ibiza.webp', type: 'Nightclub' },
        { name: 'Mercado de San Miguel', distance: '500 m', image: 'Mercado-San-Miguel_2352374850_22284906_1300x731.jpg', type: 'Market' },
        { name: 'Alhambra de Granada', distance: '4 km', image: 'que-es-la-alhambra-de-granada.jpg', type: 'Landmark' },
        { name: 'Parque Güell', distance: '2,5 km', image: 'bb.jpg', type: 'Park' },
        { name: 'Terraza Azotea del Círculo', distance: '300 m', image: 'azotea-circulo.jpg', type: 'Terrace' },
        { name: 'Discoteca Kapital', distance: '1,8 km', image: '1568190236_kapital-2-3.jpg', type: 'Nightclub' },
        { name: 'Terraza 360º', distance: '750 m', image: 'terraza.jpg', type: 'Terrace' },
        { name: 'Sala Apolo', distance: '2 km', image: 'thumb_7155_default_card.jpeg', type: 'Nightclub' },
        { name: 'El Jardín Secreto', distance: '400 m', image: 'el_jardin_secreto.jpg', type: 'Bar' },
        { name: 'Discoteca Opium Madrid', distance: '3,5 km', image: '1568138155_opium.jpg', type: 'Nightclub' },
        { name: 'Terraza del Hotel Santo Domingo', distance: '1,3 km', image: 'SUNSET-LOOKERS-Atardeceres-de-primera-DESTACADA-770x466.jpg', type: 'Terrace' },
        { name: 'La Venencia', distance: '700 m', image: '09.06.09.La-Venencia-1.018-1-2-500x300.jpg', type: 'Bar' },
        { name: 'Sala Razzmatazz', distance: '2,7 km', image: 'sala-razzmatazz.jpg', type: 'Nightclub' },
   
    ];
    
    
    const events = [

        {
            name: 'Sónar Festival',
            location: 'Barcelona, Cataluña',
            image: 'sonar.png',
            distance: "750m",
            type: 'Music Festival',
            musicGenres: ['Electronic', 'Techno', 'House']
        },
        {
            name: 'Arenal Sound',
            location: 'Burriana, Castellón',
            image: 'arenal_sound.png',
            distance: "1.2 km",
            type: 'Music Festival',
            musicGenres: ['Pop', 'Indie', 'Electronic']
        },
        {
            name: 'Discoteca Pacha Ibiza',
            location: 'Ibiza, Islas Baleares',
            image: 'pacha.png',
            distance: "2 km",
            type: 'Nightclub',
            musicGenres: ['House', 'Electronic', 'Dance']
        },
        {
            name: 'Primavera Sound',
            location: 'Barcelona, Cataluña',
            image: 'Cartel-Primavera-Sound-2024.webp',
            distance: "900m",
            type: 'Music Festival',
            musicGenres: ['Indie', 'Rock', 'Pop', 'Electronic']
        },
        {
            name: 'Festival Internacional de Benicàssim (FIB)',
            location: 'Benicàssim, Castellón',
            image: '5cfe0111b411a.jpg',
            distance: "1.5 km",
            type: 'Music Festival',
            musicGenres: ['Indie', 'Rock', 'Electronic']
        },
        {
            name: 'Discoteca Opium',
            location: 'Madrid, Comunidad de Madrid',
            image: 'opiummadrid.png',
            distance: "850m",
            type: 'Nightclub',
            musicGenres: ['Electronic', 'House', 'Hip-Hop']
        },
        {
            name: 'Mad Cool Festival',
            location: 'Madrid, Comunidad de Madrid',
            image: 'fotonoticia_20190620180742_1200.jpg',
            distance: "1.8 km",
            type: 'Music Festival',
            musicGenres: ['Rock', 'Indie', 'Electronic']
        },
        {
            name: 'Mon Madrid',
            location: 'Madrid, Comunidad de Madrid',
            image: 'monmadrdi.png',
            distance: "1.1 km",
            type: 'Nightclub',
            musicGenres: ['House', 'Dance', 'Pop']
        },
        {
            name: 'BBK Live',
            location: 'Bilbao, País Vasco',
            image: 'bbk_live.jpg',
            distance: "2.3 km",
            type: 'Music Festival',
            musicGenres: ['Rock', 'Indie', 'Pop']
        },
        {
            name: 'Discoteca Fabrik',
            location: 'Madrid, Comunidad de Madrid',
            image: '150fabrik.png',
            distance: "3 km",
            type: 'Nightclub',
            musicGenres: ['Techno', 'Electronic', 'Trance']
        },
        
        {
            name: 'Sónar Festival',
            location: 'Barcelona, Cataluña',
            image: 'sonar.png',
            distance: "750m",
            type: 'Music Festival',
            musicGenres: ['Electronic', 'Techno', 'House']
        },
        {
            name: 'Arenal Sound',
            location: 'Burriana, Castellón',
            image: 'arenal_sound.png',
            distance: "1.2 km",
            type: 'Music Festival',
            musicGenres: ['Pop', 'Indie', 'Electronic']
        },
        {
            name: 'Discoteca Pacha Ibiza',
            location: 'Ibiza, Islas Baleares',
            image: 'pacha.png',
            distance: "2 km",
            type: 'Nightclub',
            musicGenres: ['House', 'Electronic', 'Dance']
        },
        {
            name: 'Primavera Sound',
            location: 'Barcelona, Cataluña',
            image: 'Cartel-Primavera-Sound-2024.webp',
            distance: "900m",
            type: 'Music Festival',
            musicGenres: ['Indie', 'Rock', 'Pop', 'Electronic']
        },
        {
            name: 'Festival Internacional de Benicàssim (FIB)',
            location: 'Benicàssim, Castellón',
            image: '5cfe0111b411a.jpg',
            distance: "1.5 km",
            type: 'Music Festival',
            musicGenres: ['Indie', 'Rock', 'Electronic']
        },
        {
            name: 'Discoteca Opium',
            location: 'Madrid, Comunidad de Madrid',
            image: 'opiummadrid.png',
            distance: "850m",
            type: 'Nightclub',
            musicGenres: ['Electronic', 'House', 'Hip-Hop']
        },
        {
            name: 'Mad Cool Festival',
            location: 'Madrid, Comunidad de Madrid',
            image: 'fotonoticia_20190620180742_1200.jpg',
            distance: "1.8 km",
            type: 'Music Festival',
            musicGenres: ['Rock', 'Indie', 'Electronic']
        },
        {
            name: 'Mon Madrid',
            location: 'Madrid, Comunidad de Madrid',
            image: 'monmadrdi.png',
            distance: "1.1 km",
            type: 'Nightclub',
            musicGenres: ['House', 'Dance', 'Pop']
        },
        {
            name: 'BBK Live',
            location: 'Bilbao, País Vasco',
            image: 'bbk_live.jpg',
            distance: "2.3 km",
            type: 'Music Festival',
            musicGenres: ['Rock', 'Indie', 'Pop']
        },
        {
            name: 'Discoteca Fabrik',
            location: 'Madrid, Comunidad de Madrid',
            image: '150fabrik.png',
            distance: "3 km",
            type: 'Nightclub',
            musicGenres: ['Techno', 'Electronic', 'Trance']
        }
    
    ];
    
    const clubs = [
        { name: 'Amnesia Club', image: 'amnesia.png' },
        { name: 'Mon Madrid', image: 'monmadrid.png' },
        { name: 'Panda Club', image: 'pandaclub.png' },
        { name: 'Teatro Kapital', image: 'kapital.png' },
        { name: 'Florida 135', image: 'florida.png' },
        { name: 'Avocado Love', image: 'avocado.png' },
        { name: 'Boombastic', image: 'boombastic.png' },
        { name: 'Magic Lounge', image: 'magiclongue.png' },
        { name: 'Jowke', image: 'jowke.png' },
        
        { name: 'Razzmatazz', image: 'Razzmatazz.png' },
        { name: 'Opium Madrid', image: 'opium.png' },
        { name: 'Panda Club', image: 'pandaclub.png' },
        { name: 'Teatro Kapital', image: 'kapital.png' },
        { name: 'Florida 135', image: 'florida.png' },
        { name: 'Avocado Love', image: 'avocado.png' },
        { name: 'Boombastic', image: 'boombastic.png' },
        { name: 'Magic Lounge', image: 'magiclongue.png' },
        { name: 'Jowke', image: 'jowke.png' },
    ];
    
    return (
        <div className='card-container pt-4'>
            {/* <div className="overlay"></div> */}
            <div className="card-wrapper-inverse">
                {clubs.map((club, index) => (
                    <ProfileCard
                        key={index}
                        name={club.name}
                        distance={club.distance}
                        image={"./images/profiles/" + club.image}
                        type={club.type}
                        musicGenres={club.musicGenres}

                    ></ProfileCard>
                ))}
            </div>
            <div className="card-wrapper">
                {places.map((place, index) => (
                    <PlaceCard
                        key={index}
                        name={place.name}
                        distance={place.distance}
                        image={"./images/" + place.image}
                        type={place.type}
                    />
                ))}
            </div>
            <div className="card-wrapper-inverse">
                {events.map((event, index) => (
                    <EventCard
                        key={index}
                        name={event.name}
                        distance={event.distance}
                        image={"./images/" + event.image}
                        type={event.type}
                        musicGenres={event.musicGenres}

                    ></EventCard>
                ))}
            </div>
            
        </div>
    );
}


export default PlaceList;
