import './Home.css'
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import MouseMoveEffect from '../MouseMoveEffect';
import mailicon from "./mail.svg"
import igicon from "./instagram.svg"
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import PlaceList from '../PlaceList/PlaceList';

// Registra el plugin ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export function Home({ isMobile }) {

    useEffect(() => {
        // Animación de entrada de los elementos
        gsap.fromTo(
            ".home .pt-5", 
            { opacity: 0, y: 50 }, 
            { opacity: 1, y: 0, duration: 1, delay: 1.2 }
        );
        
        gsap.fromTo(
            ".home .home_usa",
            { opacity: 0, x: -50 },
            { opacity: 1, x: 0, duration: 1, delay: 0.5 }
        );

        gsap.fromTo(
            ".home .home_title",
            { opacity: 0, y: 50 },
            { opacity: 1, y: 0, duration: 1, delay: 0.2 }
        );

        gsap.fromTo(
            ".home .home_contact .item",
            { opacity: 0, y: 50, stagger: 0.3 },
            { opacity: 1, y: 0, duration: 1, delay: 1.4 }
        );

        const UPDATE = ({x, y}) => {
            gsap.set(document.documentElement, {
                '--x': gsap.utils.mapRange(0, window.innerWidth, -1 ,1, x),
                '--y': gsap.utils.mapRange(0, window.innerHeight, -1,1, y),
            });
        };

        window.addEventListener('pointermove', UPDATE);

        return () => {
            window.removeEventListener('pointermove', UPDATE);
        };
    }, []);

    return (
        <div id='home' className="home">
            <div className="background">
                <div className="overlay"></div>
                <PlaceList></PlaceList>
            </div>
            <div className="content glass pb-4">
                <div style={{transform: isMobile ? 'translateY(-4rem)' : ''}}>
                    <div  className='p-5 d-flex flex-column '>
                        <MouseMoveEffect sensitivity={isMobile ? 0 : -40}>
                            <h1 className="text-light m-0 home_title"><b>Near</b><b style={{color: "#1FB0A7"}}>U</b></h1>
                        </MouseMoveEffect>
                        <h2 className='text-light pt-5'>Encuentra los mejores planes estés donde estés</h2>
                    </div>
                </div>
                <div className='w-100 d-flex justify-content-evenly home_contact'>
                    <div className='d-flex align-items-center item'>
                        <img className='mx-2' style={{width: '2rem', color: 'white'}} src={mailicon} alt="" />
                        <h5 className='text-light m-0'>hola@nearu.es</h5>
                    </div>
                    <div className='d-flex align-items-center item'>
                        <img className='mx-2' style={{width: '2rem', color: 'white'}} src={igicon} alt="" />
                        <h5 className='text-light  m-0'><a className='text-decoration-none' href="https://www.instagram.com/nearu.es/">@nearu.es</a></h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
