import React from 'react';
import './PlaceCard.css';
import _image from './images/image3.png';

function PlaceCard({ name, distance, image, type }) {
    let placeImage;
    try {
        placeImage = require(`${image}`);
    } catch (err) {
        placeImage = require('./images/image1.png');
    }
    return (
        <div id='place' className="card">
            <img src={placeImage} alt={name} className="image" />
            <div className="info">
                <div className='name-container'>
                    <p className='name'>{name}</p>
                </div>
                <p className='type'>{type}</p>
                <p className='distance'>a {distance} de tí</p>
            </div>
        </div>
    );
}

export default PlaceCard;

